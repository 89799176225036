@font-face {
  font-family: 'KomikaHeader';
  src: local('KomikaHeader'), url("./fonts/KomikaHeader.ttf") format('truetype');
}

body {
  background: radial-gradient(circle farthest-corner at 10% 20%, rgba(14,174,87) 0%, rgba(12,116,117) 90%) fixed;
  color: #ffdc0e;
  font-family: 'Lato', sans-serif;
  text-align: center;
  margin: 10px 20px;
}

h1, h2 {
  font-family: 'KomikaHeader', sans-serif;
}

p {
  padding-top: 10px;
  font-size: 16px;
}

label {
  font-size: 16px;
}

#result {
  font-size: 20vh;
  position: relative;
  top: 25vh;
}